@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/spacings";
@use "$styles/variables/colors";

.component {
    display: flex;  
    gap: spacings.$XXS;
    height: 113px;
    overflow: hidden;
    background-color: colors.$white;
    @include mediaqueries.mediumUp {
        gap: spacings.$M;
    }
}
.fixieImage {
    margin-left: 15px;
    margin-top: -5px;
    @include mediaqueries.mediumUp {
        margin-left: 35px;
    }
}