@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/radii';

$borderSize: 8px;

.component {
  min-width: 100%;
}

.Card {
  background-color: colors.$white;
  padding: spacings.$M spacings.$S;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.label {
  position: relative;
}

.labelText {
  display: inline-block;
  margin-bottom: -$borderSize;
  padding: spacings.$XXS spacings.$S;
  border-top-left-radius: radii.$M;
  border-top-right-radius: radii.$M;
  color: colors.$white;
  @include typography.labelMTypographyStyles;
}

.labelBorder {
  width: 100%;
  height: $borderSize;
  background: colors.$purple500;
  border-top-left-radius: radii.$M;
  border-top-right-radius: radii.$M;
}