@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";
@use "$styles/variables/radii";
@use "$styles/variables/spacings";
@use "$styles/variables/fonts";

.component {
    font: fonts.$labelS;
    padding: spacings.$XS;
    display: flex;
    align-items: center;
    gap: 10px;

    &.blue {
        border-radius: radii.$S;
        border: 1px solid colors.$cyan200;
        background: colors.$cyan100;
        color: colors.$cyan600;
    }
    &.grey {
        border-radius: radii.$XS;
        border: 1px solid colors.$neutral200;
        background: colors.$neutral100;
        color: colors.$neutral600;
    }

    @include mediaqueries.smallUp {
        font-size: 16px;
    }

}