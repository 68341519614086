@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use "$styles/variables/spacings";

$bulletSize: 40px;

.modalHeading {
    margin-bottom: spacings.$L;
    margin-left: spacings.$M;
}

.compareServices {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: spacings.$XS;
    padding: spacings.$XXS;
    @include typography.labelMTypographyStyles;
    letter-spacing: -0.16px;
    color: colors.$purple500;
    background-color: colors.$white;
    cursor: pointer;
  }
  .compareIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $bulletSize;
    height: $bulletSize;
    border-radius: $bulletSize;
    background-color: colors.$purple200;
  }
