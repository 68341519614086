@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/extends/typography';
@use '$styles/variables/colors';

.loadingContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mascot {
  object-fit: contain;
  object-position: -100px 180px;
  z-index: -1;

  @include mediaqueries.upToSmall {
    display: none;
  }
}

.modalHeading,
.modalDescription {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.modalHeading {
  p {
    @extend %headlinesH5TypographyStyles;
    margin: 0;
    text-align: center;
    color: colors.$purple800;
  }
}

.modalDescription {
  color: colors.$neutral600;
  @extend %paragraphSTypography;
}

.modalVrmLookupForm {
  max-width: 380px;
  margin: auto auto 0 !important;

  @include mediaqueries.smallUp {
    padding: 0 30px;
    translate: 10px 0;
  }

  input {
    min-width: auto;
  }
}
