@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/radii';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/elevation';

.component {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  br {
    display: block;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    td {
      display: block;
      align-self: flex-start;

      padding: spacings.$XS;
      @include typography.paragraphMTypography;
      color: colors.$neutral600;
      .checked {
        color: colors.$green300;
      }
      .unchecked {
        color: colors.$neutral300;
      }
    }
    td:nth-of-type(1) {
      width: 100%;
      border-top: 1px solid colors.$neutral200;
      background-color: colors.$neutral100;
    }
    td:not(:nth-of-type(1)) {
      width: 33%;
      flex-shrink: 1;
    }
  }
  tfoot {
    td {
      @include typography.paragraphXSTypography;
      color: colors.$neutral800;
      font-weight: 600;
    }
    tr {
      .button {
        display: none;
        &:last-of-type {
          display: block;
          width: 100%;
        }
      }
    }
    td:first-of-type {
      display: none;
    }
    span {
      display: block;
    }
    .productPrice {
      @include typography.headlinesH6TypographyStyles;
      margin-bottom: 0;
    }
  }

  .tableHead {
    display: block;
    margin: 0 spacings.$M;
  }
  .tableHeadRow {
    th {
      display: flex;
      flex: 1 1 0;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 60px;
      border-left: 1px solid colors.$neutral200;
      border-top: 1px solid colors.$neutral200;
      @include typography.labelLTypographyStyles;
      font-weight: 600;
      div {
        position: absolute;
        top: 0;
        z-index: elevation.$level1;
        width: 100%;
        span {
          display: inline-block;
          transform: translateY(-18px) rotate(2deg);
          word-spacing: 0;
          @include typography.labelXSTypographyStyles;
          line-height: 12px;
          background-color: colors.$purple500;
          color: colors.$white;
          border-radius: 32px;
          padding: spacings.$XXXS spacings.$XXS;
          white-space: nowrap;
        }
      }
    }
    th:nth-of-type(1) {
      display: none;
    }
    th:nth-of-type(2) {
      border-top-left-radius: radii.$S;
    }
    th:last-of-type {
      border-right: 1px solid colors.$neutral200;
      border-top-right-radius: radii.$S;
    }
    th:not(:nth-of-type(1)) {
      word-spacing: 100vw;
    }
  }
  .getQuoteButton {
    width: 100%;
  }
  @include mediaqueries.smallUp {
    &:not(.forceMobile) {
      br:not(:nth-of-type(1)) {
        display: none;
      }
      tr {
        display: table-row;
        background: colors.$white;
        td {
          text-align: center;
          display: table-cell;
          padding: spacings.$XXS spacings.$M;
        }
        th:nth-of-type(1),
        td:nth-of-type(1) {
          text-align: left;
        }
        td:nth-of-type(1) {
          display: flex;
          align-items: center;
          width: auto;
          min-height: 64px;
          border-top: 0;
          background-color: colors.$white;
        }
      }
      tbody tr {
        border-bottom: 1px solid colors.$neutral200;
      }
      tfoot {
        tr td {
          padding: spacings.$S spacings.$S spacings.$XXS;
        }
        .tableFooterButtons {
          .button {
            display: table-cell;
          }
        }

        tr {
          .button {
            &:last-of-type {
              display: table-cell;
              width: auto;
            }
          }
        }
      }

      .tableHead {
        display: table-header-group;
        tr {
          border-bottom: 0;
        }
        th:nth-of-type(1) {
          display: table-cell;
        }
        th {
          display: table-cell;
          width: 25%;
          height: 96px;
          background-color: colors.$purple100;
          @include typography.headlinesH5TypographyStyles;
          color: colors.$purple800;
          font-weight: 600;
          padding: spacings.$S;
          margin-bottom: 0;
          border: 0;

          div {
            span {
              transform: translateY(-15px) translateX(-10px) rotate(-3deg);
              font-size: 14px;
              padding: spacings.$XXS spacings.$XS;
              letter-spacing: -0.14px;
            }
          }
        }
        th:nth-of-type(1) {
          border-top-left-radius: radii.$S;
          border-bottom-left-radius: radii.$S;
          padding-left: spacings.$M;
        }
        th:nth-of-type(2) {
          border-radius: 0;
        }
        th:last-of-type {
          border-top-right-radius: radii.$S;
          border-bottom-right-radius: radii.$S;
          border: 0;
        }
      }
      .getQuoteButton {
        width: auto;
      }
    }
  }
}

.AdditionalText{
  font-size: 12px;
  line-height: 14px;
  color: colors.$neutral400;
  width: 80%;
  max-width: 220px;
  margin: auto;
  font-weight: normal!important;
}

.PurpleText{
  color: colors.$purple500!important;
}