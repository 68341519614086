@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.component {
  h4 {
    text-align: center;
    @include typography.headlinesH5TypographyStyles;
    margin-bottom: 0;
  }
  h3 {
    font-size: 22px;
    margin-bottom: spacings.$S;
  }
  p {
    @include typography.labelSTypographyStyles;
    letter-spacing: -0.14px;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: spacings.$S;
  color: colors.$purple800;
  text-align: center;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH2TypographyStyles;
  }
}

.comparisonTableDesktop {
  display: none;
  @include mediaqueries.mediumUp {
    display: grid;
  }
}
.spacing {
  display: none;
  @include mediaqueries.mediumUp {
    display: block;
  }
}
.serviceMobile {
  @include mediaqueries.mediumUp {
    display: none;
  }
}
.fixieCardContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: spacings.$XXXS;
  justify-content: center;
  margin: 0 spacings.$M;
  align-items: flex-start;

  h6 {
    margin-bottom: 0 !important;
    color: colors.$purple800;
    @include typography.labelMTypographyStyles;
    letter-spacing: -0.14px;
  }
  .accessPage {
    color: colors.$purple500;
    &:hover {
      color: white;
    }
  }
  .accessPageDesktop {
    display: none;
  }
  .accessPageMobile {
    margin-left: -18px;
  }
  @include mediaqueries.mediumUp {
    flex-direction: row;
    align-items: center;
    gap: 100px;
    margin-right: spacings.$M;
    h6 {
      @include typography.headlinesH6TypographyStyles;
    }
    .accessPageDesktop {
      display: block;
    }
    .accessPageMobile {
      display: none;
    }
  }
}
.comparisonTableCard {
  padding: spacings.$L spacings.$M spacings.$M spacings.$M;
  background-color: colors.$white;
}
.linkSection {
  margin-top: spacings.$M;
}
@include mediaqueries.upToMedium {
  .fixieInfo p {
    @include typography.labelMTypographyStyles;
  }
  .servicesSection {
    display: flex;
    flex-direction: column;
    gap: spacings.$M;
  }
  .servicesCards {
    display: flex;
    flex-direction: column;
    gap: spacings.$L;
  }

  .servicesContent {
    display: flex;
    flex-direction: column;
    gap: spacings.$S;
  }
  .description {
    p,
    li,
    ul {
      @include typography.paragraphMTypography;
      margin-bottom: spacings.$S;
      color: colors.$neutral600;
    }
    ul {
      list-style-type: none;
      margin-block-start: 0;
      padding-inline-start: 0;
      li {
        position: relative;
        padding-left: spacings.$M;
        p {
          font-weight: normal !important;
        }
        &::before {
          content: '';
          background: colors.$green300;
          mask: url('/icons/general/check-circle-broken.svg');
          mask-size: 16px;
          width: 16px;
          height: 16px;
          display: inline-flex;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }
  }
  .price {
    display: flex;
    gap: spacings.$XXXS;
    justify-content: right;
    align-items: baseline;
    font-weight: 600;
    @include typography.labelMTypographyStyles;
    span {
      @include typography.headlinesH6TypographyStyles;
      font-size: 24px;
      margin-bottom: 0;
    }
  }
}

:export {
  labelColor1: linear-gradient(0deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%),
    var(--colour-purple-500, #5739d8);
  labelColor2: colors.$purple500;
  labelColor3: colors.$purple700;
}
